type LoginInputProps = React.ComponentPropsWithoutRef<"input"> & {
  error?: {
    message: string;
  };
  inputRef?: React.Ref<HTMLInputElement>;
};

const LoginInput = ({ inputRef, ...rest }: LoginInputProps) => {
  return (
    <input
      type="text"
      className="w-full rounded border border-neutral-300 px-5 py-4 text-center focus:border-primary-700 focus:outline-none focus:ring-primary-700"
      ref={inputRef}
      autoFocus
      {...rest}
    />
  );
};

export default LoginInput;
