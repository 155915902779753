"use client";

import { PhoneNumber, isPossiblePhoneNumber, parsePhoneNumberWithError, ParseError } from "libphonenumber-js";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { useState, ChangeEvent, useRef, useEffect, useCallback } from "react";
import Balancer from "react-wrap-balancer";
import { Transition } from "@headlessui/react";

import useKeypress from "react-use-keypress";
import LoginInput from "components/LoginInput";
import Button from "components/Button";
import useInviteUser from "hooks/useInviteUser";
import useVerifyOtp from "hooks/useVerifyOtp";
import useSessionState from "hooks/useSessionState";
import useErrorLogger from "hooks/useErrorLogger";
import { ButtonSize, ButtonAppearance, ERROR_TYPES } from "utils/constants";
import Loader from "components/Loader";
import { EMAIL_REGEX } from "utils/texts";
import { validateAuthToken } from "lib/authApi";
import toast from "utils/toast";
import OtpCodeInput from "components/OtpCodeInput";

const IS_DEV = !process.env.NEXT_PUBLIC_VERCEL_URL;
const LOCAL_URL = process.env.NEXT_PUBLIC_LOCAL_URL;

const ClientLogin = ({ redirectTo, isStaffLogin }: { redirectTo?: string; isStaffLogin?: boolean }) => {
  const [isMounted, setIsMounted] = useState(false);
  const searchParams = useSearchParams();
  const router = useRouter();
  const { session, isSessionLoading } = useSessionState();
  const { logError } = useErrorLogger();

  const [emailPhone, setEmailPhone] = useState("");
  const [error, setError] = useState<{ message: string } | undefined>(undefined);
  const [channel, setChannel] = useState("phone");
  const [isLoading, setIsLoading] = useState(false);
  const { sendMagicLink } = useInviteUser(true);
  const { verifyOtp, loading: isVerifyingOTP } = useVerifyOtp();
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [parsedPhone, setParsedPhone] = useState<PhoneNumber | undefined>(undefined);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [resendText, setResendText] = useState("Resend code");
  const [showNewUser, setShowNewUser] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initTokenValidated = useRef(false);
  const otpInputRef = useRef<HTMLInputElement | null>(null);
  const pageUrl = typeof window !== "undefined" ? window.location.href : "";

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const supabaseAddedParams = pageUrl ? new URLSearchParams(new URL(pageUrl).hash.slice(1)) : undefined;
  const accessToken = searchParams?.get("access_token") || supabaseAddedParams?.get("access_token");

  const getIsValidInput = useCallback(() => {
    if (EMAIL_REGEX.test(String(emailPhone).toLowerCase())) {
      setChannel("email");
      return true;
    }
    if (emailPhone && isPossiblePhoneNumber(emailPhone, "US")) {
      setChannel("phone");
      return true;
    }
    return false;
  }, [emailPhone]);

  const isValidInput = getIsValidInput();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.target.value;
    if (emailPhone.length > 13 && !isNaN(+emailPhone)) {
      if (!isNaN(+val) && val.length > 14) {
        return false;
      } else {
        setEmailPhone(val);
      }
    }
    setEmailPhone(val);
    setError(undefined);
  };

  const handleSubmit = async (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();

    if (channel === "email") {
      // ##HARDCODED check for soldave domain email
      if (emailPhone.endsWith("@soldave.com")) {
        toast.error("Please use internal site to login", { autoClose: 2000 });
        return;
      }
      setIsLoading(true);
      const success = await sendMagicLink({
        user: { email: emailPhone },
        redirectUrl: redirectTo || `${IS_DEV ? LOCAL_URL : window.location.origin}/table`,
        shouldCreateUser: false
      });
      setIsLoading(false);
      if (success) {
        setShowEmailSent(true);
      } else {
        setShowNewUser(true);
        setShowEmailSent(false);
      }
    } else {
      let finalPhoneNumber: PhoneNumber;
      try {
        finalPhoneNumber = parsePhoneNumberWithError(emailPhone, "US");
      } catch (error) {
        if (error instanceof ParseError) {
          // Not a phone number, non-existent country, etc.
          toast.error("Invalid Mobile Number");
          logError({
            error: {
              name: "Client Phone Parse Error",
              message: error.message
            },
            message: `Failed parse phone: ${emailPhone}`,
            source: "ClientLogin - handleSubmit",
            type: ERROR_TYPES.CLIENT_PAGE,
            url: window.location.href,
            additionalInfo: {
              phone: emailPhone
            }
          });
          return;
        }
        return;
      }

      if (!finalPhoneNumber) {
        return;
      }
      setParsedPhone(finalPhoneNumber);
      setIsLoading(true);

      const success = await sendMagicLink(
        {
          user: { phone: finalPhoneNumber.number },
          redirectUrl: redirectTo || `${IS_DEV ? LOCAL_URL : window.location.origin}/table`,
          shouldCreateUser: false
        },
        "phone"
      );
      setIsLoading(false);
      if (success) {
        setShowOTPForm(true);
      } else {
        setIsLoading(false);
        logError({
          error: {
            name: "Sold Ave App Error",
            message: "Failed to send OTP to" + finalPhoneNumber.number
          },
          message: `Failed to send OTP to: ${finalPhoneNumber.number}`,
          source: "ClientLogin - handleSubmit",
          type: ERROR_TYPES.CLIENT_PAGE,
          url: window.location.href,
          additionalInfo: {
            phone: finalPhoneNumber.number,
            clientPhoneInput: emailPhone
          }
        });
        toast.error(`There was an issue sending the code, please check your phone number and try again`, {
          autoClose: 2000
        });
      }
    }
  };

  const resendOTP = async (e: MouseEvent) => {
    e.preventDefault();
    if (resendText === "Sent" || !parsedPhone?.number) {
      return;
    }
    setIsLoading(true);
    const success = await sendMagicLink({ user: { phone: parsedPhone?.number }, redirectUrl: redirectTo }, "phone");
    setIsLoading(false);
    if (!success) {
      setError({ message: "There was an issue resending the code, please try again" });
      return;
    } else {
      setResendText("Sent");
      setInterval(() => {
        setResendText("Resend code");
      }, 2000);
    }
  };

  const handleCodeSubmit = async (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    if (channel === "phone" && !parsedPhone) {
      return;
    }

    const token = userCode.trim();
    if (!token) {
      toast.error("Please Enter OTP");
      return;
    }
    const success = await verifyOtp({
      phone: channel === "phone" && parsedPhone ? parsedPhone.number : undefined,
      token,
      email: channel === "email" ? emailPhone : undefined
    });
    if (success) {
      router.push(redirectTo ? redirectTo : "/");
    } else {
      if (otpInputRef.current) {
        otpInputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (initTokenValidated.current) return;
    const user_token = searchParams?.get("user_token");
    if (user_token) {
      initTokenValidated.current = true;
      validateAuthToken(user_token)
        .then((result) => {
          if (!result.success) {
            logError({
              error: {
                name: "Sold Ave App Error",
                message: result.error || "Failed to validate token"
              },
              message: `Failed to  validate token: ${user_token}`,
              source: "ClientLogin - validateToken",
              type: ERROR_TYPES.USER_AUTH,
              url: window.location.href,
              additionalInfo: {
                user_token
              }
            });
            router.push("/login");
            return;
          }
          const url = result.data?.loginUrl;
          if (url) {
            window.location.href = url;
          } else {
            router.push("/login");
          }
        })
        .catch((err) => {
          logError({
            error: err,
            message: `Failed to validate token: ${user_token}`,
            source: "ClientLogin - validateToken",
            type: ERROR_TYPES.USER_AUTH,
            url: window.location.href,
            additionalInfo: {
              user_token
            }
          });
          router.push("/login");
        });
    }
  }, [searchParams, router, logError]);

  const showLoader =
    searchParams?.get("source") === "quiz" ||
    searchParams?.get("token") ||
    searchParams?.get("user_token") ||
    accessToken ||
    !pageUrl ||
    isSessionLoading ||
    !!session;

  useKeypress(["Enter"], (e: KeyboardEvent) => {
    if (!showEmailSent && !showOTPForm && !showNewUser && !session && isValidInput) {
      handleSubmit(e);
    } else if ((showOTPForm || showEmailSent) && userCode && !isVerifyingOTP) {
      handleCodeSubmit(e);
    }
  });

  if (!isMounted) {
    return null;
  }

  if (showLoader)
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader className="text-base-disabled" />
      </div>
    );

  return (
    <>
      {showEmailSent ? (
        <>
          <div className="mx-auto flex h-full max-w-[421px] flex-col items-center px-5 text-center">
            <div className="flex w-full flex-1 flex-col items-center justify-center pb-10">
              <h2 className="lg:text-5xl mb-7 mt-6 text-4xl">We&apos;ve emailed you a sign in link.</h2>
              <p className="font-matter text-md text-[#0A1A1F]">
                We sent an email to <span className="font-medium">{emailPhone}</span> with a link that you can tap to
                sign in.
              </p>
              <p className="my-4 text-md font-medium text-[#0A1A1F]">OR</p>
              <OtpCodeInput
                description="Enter code sent to your mail"
                onChange={(value) => {
                  setUserCode(value);
                  if (error) {
                    setError(undefined);
                  }
                }}
                value={userCode}
                autoComplete="one-time-code"
                error={error}
                autoFocus
                inputRef={otpInputRef}
              />
              <div className="mt-4 min-h-[80px] w-full lg:text-center">
                <Transition
                  show={true}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Button
                    label="Continue"
                    size={ButtonSize.MD}
                    appearance={ButtonAppearance.PRIMARY}
                    className="flex w-full !rounded-full"
                    //@ts-ignore
                    onClick={handleCodeSubmit}
                    isLoading={isVerifyingOTP}
                    disabled={!userCode || isVerifyingOTP}
                  />
                </Transition>
                {error ? <div className="text-boh mt-3 text-sm">{error.message}</div> : null}
              </div>
            </div>

            <div className="flex w-full flex-col border-t border-neutral-200 pt-8 lg:items-center">
              <div className="flex items-center gap-x-8 text-sm text-[#0A1A1F]">
                <a
                  href="https://mail.google.com/mail/u/0/"
                  className="flex items-center gap-x-2.5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12534_187370)">
                      <path d="M2.5 2H14.5V14H2.5V2Z" fill="#ECEFF1" />
                      <path d="M8.5 9.262L14.5 14V4.646L8.5 9.262Z" fill="#CFD8DC" />
                      <path
                        d="M15 2H14.5L8.5 6.738L2.5 2H2C1.60218 2 1.22064 2.15804 0.93934 2.43934C0.658035 2.72064 0.5 3.10218 0.5 3.5L0.5 12.5C0.5 12.8978 0.658035 13.2794 0.93934 13.5607C1.22064 13.842 1.60218 14 2 14H2.5V4.646L8.5 9.261L14.5 4.645V14H15C15.3978 14 15.7794 13.842 16.0607 13.5607C16.342 13.2794 16.5 12.8978 16.5 12.5V3.5C16.5 3.10218 16.342 2.72064 16.0607 2.43934C15.7794 2.15804 15.3978 2 15 2Z"
                        fill="#F44336"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12534_187370">
                        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Open Gmail</span>
                </a>
                <a
                  href="https://outlook.live.com/mail/0/inbox"
                  className="flex items-center gap-x-2.5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12534_187377)">
                      <path
                        d="M8.3631 0H9.45007V3.60457C11.333 3.60817 13.2143 3.59932 15.0956 3.60457C15.4663 3.58653 15.9735 3.69277 15.9663 4.15609C16.0354 6.43595 15.9683 8.72139 15.9993 11.0032C16.0066 11.4069 15.994 12.0433 15.4395 12.0486C13.4454 12.1171 11.4461 12.0538 9.4504 12.0754V15.5H8.32308C5.55291 15.0006 2.77546 14.5412 0.00198413 14.0573C0.000661376 9.85296 0 5.649 0 1.44537C2.78836 0.960737 5.57639 0.491845 8.3631 0Z"
                        fill="#0071C5"
                      />
                      <path
                        d="M9.45009 4.14628C11.464 4.14792 13.4762 4.14104 15.4901 4.14989C15.4832 4.26781 15.4524 4.38314 15.3996 4.48904C15.3469 4.59494 15.2732 4.68924 15.1829 4.76633C13.9796 5.87834 12.78 6.99319 11.584 8.11088C10.8625 7.46394 10.1621 6.79503 9.45009 6.13727V4.14628ZM3.89718 4.80503C4.40333 4.63111 4.95679 4.65137 5.44862 4.86183C5.94045 5.0723 6.335 5.4577 6.55459 5.94217C7.14751 7.29409 7.06352 9.1431 5.88561 10.1652C4.79963 11.0862 2.98085 10.5616 2.48647 9.25327C1.83601 7.74462 2.15214 5.42311 3.89718 4.80503Z"
                        fill="white"
                      />
                      <path
                        d="M12.1548 8.27091C13.246 7.24165 14.3486 6.21992 15.4464 5.19623C15.4517 7.30854 15.4464 9.42249 15.4484 11.5348H9.45007C9.45007 9.96308 9.45062 8.39202 9.45173 6.82161C10.1296 7.41642 10.7368 8.09418 11.4567 8.63652C11.753 8.69095 11.953 8.43683 12.1548 8.27091Z"
                        fill="white"
                      />
                      <path
                        d="M4.07341 5.88806C4.3125 5.78334 4.58322 5.77461 4.82866 5.8637C5.07411 5.9528 5.27516 6.13278 5.38955 6.36581C5.73031 7.10075 5.77456 7.93712 5.51322 8.70338C5.34424 9.23851 4.75264 9.68019 4.18088 9.46935C3.4666 9.24572 3.25033 8.40598 3.24305 7.74822C3.23379 7.06685 3.39186 6.21793 4.07341 5.88806Z"
                        fill="#0071C5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12534_187377">
                        <rect width="16" height="15.5" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span>Open Outlook</span>
                </a>
              </div>
              <p className="mt-4 text-sm text-[#59524F]">Can&apos;t find your link? Check your spam folder!</p>
            </div>
          </div>
          <div />
        </>
      ) : null}

      {showOTPForm ? (
        <>
          <div className="mx-auto flex w-full max-w-[410px] flex-col items-center px-5 text-center">
            <h2 className="mb-9 mt-6 text-3xl">
              <Balancer>Enter your verification code</Balancer>
            </h2>
            {parsedPhone?.number ? (
              <p className="mb-3 text-md">
                We sent a code to <b>{parsedPhone.formatNational()}</b>.
              </p>
            ) : null}

            <OtpCodeInput
              description="Enter your code"
              onChange={(value) => {
                setUserCode(value);
                if (error) {
                  setError(undefined);
                }
              }}
              value={userCode}
              autoComplete="one-time-code"
              error={error}
              autoFocus
              inputRef={otpInputRef}
            />

            <div className="mt-4 min-h-[80px] w-full lg:text-center">
              <Transition
                show={true}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Button
                  label="Continue"
                  size={ButtonSize.MD}
                  appearance={ButtonAppearance.PRIMARY}
                  className="flex w-full !rounded-full"
                  //@ts-ignore
                  onClick={handleCodeSubmit}
                  isLoading={isVerifyingOTP}
                  disabled={!userCode || isVerifyingOTP}
                />
              </Transition>
              {error ? <div className="text-boh mt-3 text-sm">{error.message}</div> : null}

              <button
                className="mt-11 text-sm text-primary-700 underline underline-offset-2"
                //@ts-ignore
                onClick={resendOTP}
              >
                {resendText}
              </button>
            </div>
          </div>
          <div />
        </>
      ) : null}

      {!showEmailSent && !showOTPForm && !showNewUser && !session && (
        <>
          <div className="w-full max-w-[410px] space-y-8" suppressHydrationWarning>
            <h1 className="text-neutral-900 lg:text-5xl mt-3 text-center font-matter text-4xl font-medium">Welcome!</h1>
            <div className="mt-2 space-y-6">
              <input type="hidden" name="remember" value="true" />
              <div className="-space-y-px rounded-md">
                <div>
                  <LoginInput
                    placeholder="Email or Mobile Number"
                    onChange={handleChange}
                    value={emailPhone}
                    autoComplete="one-time-code"
                    error={error}
                    inputRef={inputRef}
                    autoFocus
                  />
                </div>
              </div>

              <div className="mt-4 min-h-[80px] text-center">
                <Transition
                  show={isValidInput}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Button
                    label="Sign In"
                    size={ButtonSize.MD}
                    className="inline-flex w-full !rounded-full border border-transparent bg-[#183837] text-white hover:border-[#463f3c00] hover:!bg-[#0e272f]"
                    //   @ts-ignore
                    onClick={handleSubmit}
                    appearance={ButtonAppearance.PRIMARY}
                    isLoading={isLoading}
                  />
                </Transition>
              </div>
            </div>
          </div>
        </>
      )}

      {showNewUser && !isStaffLogin ? (
        <>
          <div className="mx-auto flex w-full max-w-[410px] flex-col items-center px-5 text-center">
            <h2 className="lg:text-5xl mb-9 mt-6 text-4xl">
              <Balancer>Hey there, looks like you&apos;re new here!</Balancer>
            </h2>
            <Link href="#" className="w-full">
              <Button
                label="Tell us about your project"
                appearance={ButtonAppearance.PRIMARY}
                size={ButtonSize.MD}
                className="w-full !rounded-full"
              />
            </Link>
            <div className="mt-12 text-md">
              {channel === "email"
                ? `The email you entered ${emailPhone} doesn’t match any account we have.`
                : `The phone number you entered ${parsedPhone?.format("NATIONAL")} doesn’t match any account we have.`}
            </div>
            <div className="mt-3">
              <button
                className="text-primary-700 underline underline-offset-2"
                onClick={() => {
                  setEmailPhone("");
                  setShowNewUser(false);
                  setShowEmailSent(false);
                  setShowOTPForm(false);
                }}
              >{`I entered my ${channel === "email" ? "email" : "number"} wrong`}</button>
            </div>
          </div>
          <div />
        </>
      ) : null}
    </>
  );
};

export default ClientLogin;
