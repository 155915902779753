import { useCallback, useRef, useState } from "react";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { verifyEmailOTP, verifyPhoneOTP } from "lib/authApi";
import { ERROR_TYPES, TOAST_TYPE } from "utils/constants";
import toast, { ToastId } from "utils/toast";
import useErrorLogger from "./useErrorLogger";

const useVerifyOtp = () => {
  const progressToastId = useRef<ToastId | null>(null);
  const [loading, setLoading] = useState(false);
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();

  const verifyOtp = useCallback(
    async ({ phone, token, email }: { phone?: string; token: string; email?: string }) => {
      if (!phone && !email) {
        toast.error("One of email or phone number is required", {
          autoClose: 2000
        });
        return false;
      }

      if (!token) {
        toast.error("OTP is required", {
          autoClose: 2000
        });
        return false;
      }

      setLoading(true);
      progressToastId.current = toast.success(`Verifying OTP...`, {
        autoClose: false
      });

      try {
        let res;
        if (phone) {
          res = await verifyPhoneOTP({ phone, token });
        } else if (email) {
          res = await verifyEmailOTP({ email, token });
        }
        if (res) {
          if (res.success) {
            toast.update(progressToastId.current, `OTP Verified successfully`, {
              type: TOAST_TYPE.SUCCESS,
              autoClose: 2000
            });
            setLoading(false);
            supabaseClient.auth.setSession(res.session);
            return true;
          } else {
            toast.update(progressToastId.current, res.error, {
              type: TOAST_TYPE.ERROR,
              autoClose: 2000
            });
            setLoading(false);
            return false;
          }
        }
      } catch (err) {
        logError({
          error: err as Error,
          message: `Failed to verify OTP`,
          source: "useVerifyOtp - verifyOtp",
          type: ERROR_TYPES.HOOKS,
          url: window.location.href,
          additionalInfo: {
            phone,
            token
          }
        });
        toast.update(progressToastId.current, `Failed to verify OTP`, {
          type: TOAST_TYPE.ERROR,
          autoClose: 2000
        });
        setLoading(false);
        return false;
      }
    },
    [logError, supabaseClient.auth]
  );

  return {
    verifyOtp,
    loading
  };
};

export default useVerifyOtp;
